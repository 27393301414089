.menu-container {
  border-radius: 7px;
  border: 2px solid #f5f5f8;
  min-width: 265px !important;
}
.item-container {
  border-radius: 7px;
  border: 2px solid #f5f5f8;
}
.item-container-selectable {
  border-radius: 7px;
  border: 2px solid #f5f5f8;
}
.menu-card-heading {
  text-transform: capitalize;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
}
.menu-icons {
  color: #b2b2b2;
}

.menu-icons-active {
  color: #00983d !important;
}
.menu-card-body {
  border-radius: 16px;
  background-color: #fbfbfb;
  border: 1px solid #f5f5f8;
}
.user-card-details {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #666666;
}
.user-card-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #000000;
  border-bottom: 1px solid #00983d;
}

div.menu-card-item:nth-child(n) {
  border-bottom: 1px solid #dddddd;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #010202;
  text-transform: capitalize;
  cursor: pointer;
}
.profile-icons {
  margin: auto 0px !important;
}

div.menu-card-item:last-child {
  border-bottom: 0px;
}
.profile-button-active {
  background: #00983d !important;
  color: #ffffff !important;
}
.profile-button {
  font-style: normal;
  font-weight: 500;
  font-size: 15px !important;
  min-width: 70px !important;
}
.profile-save-button {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.profile-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.profile-button-inactive {
  background: #ffffff !important;
  color: #00983d !important;
  border-color: #00983d !important;
}
.profile-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}
span.col-md-9.justify-content-start.selected {
  color: #00983d;
}
