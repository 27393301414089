.toast-wrap {
  /* top: 170px; */
  bottom: 30px;
  left: 30px;
  /* transform: translateX(-50%) translateY(-10px); */
  transform: translateY(-10px);
  animation: fade-out 2.5s ease;
  z-index: 99999;
  opacity: 0;
  pointer-events: none;
}
.toast-wrap.toast-wrap-center{
  bottom: auto;
  bottom:unset;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  animation: fade-out-center 2.5s ease;
  width: 90%;
}
.toast-box {
  color: white;
  border-radius: 8px;
}
@keyframes fade-out {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  20% {
    opacity: 1;
    transform: translateY(0px);
  }
  80% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}
@keyframes fade-out-center {
  0% {
    opacity: 0;
    transform: translateY(-10px) translateX(-50%);
  }
  20% {
    opacity: 1;
    transform: translateY(0px) translateX(-50%);
  }
  80% {
    opacity: 1;
    transform: translateY(0px) translateX(-50%);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px) translateX(-50%);
  }
}
