.footer {
  background-color: #191C27;
  padding:32px;
  font-family: "Gotham";
}

.footer .divider-gray {
  border-color: #747474;
}

.trademark {
  color:#747474;
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
}

.footer .nesto-logo-section {
  position:relative;
}

.footer .nesto-logo-section .tagline {
  color:#FFFFFF;
  font-size: 13px;
  line-height: 15.25px;
  font-weight: 400;
  position: absolute;
  left: 60px;
  top:60px;
  white-space: nowrap;
}

.footer .nesto-logo-section a {
  color:white;
}

.footer .link-list {

}

.link-list .heading {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color:#FFFFFF;
  margin-bottom: 24px;
}

.link-list ul {
  list-style-type: none;
  padding:0;
}

.link-list li {
  margin-bottom: 16px;
}

.link-list li a {
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
  color:#FFFFFF;
}

.link-list li a:hover {
  color:#0d6efd;
}

.faq {
  margin-bottom: 32px;
}

.faq .faq-header {
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color:white;
}

.faq .faq-qa {
  background-color: #202537; 
  padding:8px;
  margin-bottom: 16px;
}

.faq .faq-q {
  font-weight: 325;
  font-size: 16px;
  line-height: 24px;
  color: #D1D1D1;
}

.faq .faq-a {
  font-weight: 325;
  font-size: 16px;
  line-height: 24px; 
  color: #F2F2F2;
  margin-left: 32px;
}

.faq .faq-icon {
  color: #F2F2F2;
  margin-right: 16px;
}