.no-margin-banner-cta_btn {
  border: none;
  padding: 0.81rem 8.2rem 0.81rem 8.2rem;
  font-weight: 700;
  color: white;
  border-radius: 0.5rem;
  background: black;
  margin-left: 0 !important;
}

.no-margin-banner-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.NoMarginBanner__image {
  width: 35%;
}

.no-margin-banner_content {
  /* padding: 1rem; */
  /* padding-left: 2rem; */
}

.no-margin-banner {
  /* margin: 0.5rem auto 0.5rem auto; */
  padding: 0.5rem 4rem 0.5rem 2rem;
}

.no-margin-discount-label {
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .no-margin-banner-title {
    font-size: 1rem;
    font-weight: 700;
  }
  .NoMarginBanner__image {
    width: 10.55rem;
  }
  .no-margin-banner-cta_btn {
    border: none;
    padding: 0.4rem 0.75rem 0.4rem 0.75rem;
    font-weight: 700;
    color: white;
    font-size: 0.825rem;
    border-radius: 0.5rem;
    background: black;
    margin-left: 0 !important;
  }
  /* .no-margin-banner {
    margin-bottom: 1.2rem;
  } */
  .NoMarginBanner__image {
    width: 42%;
  }
  .no-margin-banner {
    padding: 2rem 1rem 1rem 1.5rem;
  }
}
