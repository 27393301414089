.cart-item-delete{
  position: absolute;
  bottom:5px;
  right:15px;
}
.cart-page-product-img{
  max-width:90px;
  max-height:90px;
}
.cart-page-product-img-wrap{
  min-height: 90px;
}