.hr-productlist-container {
  margin-top: 30px;
  margin-bottom: 30px;
}
.hr-productlist-title {
  font-weight: bold;
  font-size: 17px;
  margin:0px;
}
.list-arrow {
  width:36px;
  height:36px;
  border-radius: 50%;
  cursor: pointer;
}
.list-arrow.left{
  background: #00983D url('../../assets/images/arrow-left.svg') no-repeat 12.5px center;
  margin-right:12px;
}
.list-arrow.right{
  background: #00983D url('../../assets/images/arrow-right.svg') no-repeat 15px center;
}
.list-arrow.swiper-button-disabled{
  background-color: #E5E5E5 !important;
}
.hr-productlist-container .swiper-container {
  margin: 0px;
}
.hr-productlist-container .swiper-slide {
  display: block;
  width: auto;
}