@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
html,
body {
  margin: 0px;
  font-family: "Gotham";
  box-sizing: border-box;
}
.container {
  max-width: 1336px !important;
}
.homepage-heading {
  font-weight: bold;
  font-size: 24px;
  line-height: 22px;
  color: #000000;
}
.click-anim {
  transform: scale(1);
  transition: transform 0.1s ease;
  cursor: pointer;
}
.click-anim:active {
  transform: scale(0.95);
  /* box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2); */
}
.border-line-all {
  border: 1px solid #e5e5e5;
}
.container-radius {
  border-radius: 7px;
}
.border-line {
  border-bottom: 1px solid #e5e5e5;
}
.breadcrumbs {
  font-size: 14px;
}
.breadcrumbs-link {
  color: #000;
}
.bold-label {
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  color: #000000;
}
.green-label {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #00983d;
}
.paragraph-info {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  word-break: break-all;
  word-break: break-word;
}
.faded-text {
  font-size: 15px;
  line-height: 18px;
  color: #7e7f80;
}
.primary-button,
.secondary-button,
.primary-button:hover,
.secondary-button :hover {
  display: inline-block;
  text-decoration: none;
  text-align: center;
}
.primary-button {
  background-color: #00983d;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #ffffff;
  padding: 9px 20px;
  border: 1px solid #00983d;
}
.button-disabled {
  background-color: lightgray;
  border: 1px solid lightgray;
  color: #ffffff;
}
.secondary-button {
  background-color: transparent;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #00983d;
  padding: 9px 20px;
  border: 1px solid #00983d;
}
.rounded-button {
  border-radius: 100px !important;
}
.delete-button {
  width: 22px;
  height: 22px;
  background: #aaa;
  border-radius: 6.6px;
  border: 0px;
}
.light-border-all {
  background: #ffffff;
  border: 1.11881px solid #f5f5f8;
  border-radius: 8.95049px;
}
.text-14-600 {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #111a2c;
}
.text-15 {
  font-size: 15.6634px;
  line-height: 18px;
  color: #111a2c;
}
.faded-color {
  color: #7e7f80;
}
.mild-faded-color {
  color: #666666;
}
.text-13 {
  font-size: 13px;
  line-height: 16px;
}
.text-14 {
  font-size: 14px;
  line-height: 16px;
}
.text-18 {
  font-size: 18px;
  line-height: 21px;
}
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-25 {
  font-size: 25px;
}
.fw-300 {
  font-weight: 300;
}
.fw-500 {
  font-weight: 500;
}
.fw-700 {
  font-weight: 700;
}
.fw-900 {
  font-weight: 900;
}
.underline {
  text-decoration: underline;
}
.c-pointer {
  cursor: pointer;
}
.color-green {
  color: #00983d;
}
.bg-gray {
  background-color: rgba(187, 189, 193, 0.18);
}
.toggle-switch {
  width: 48px;
  height: 28px;
  background-color: rgba(187, 189, 193, 0.5);
  border: 0px;
  border-radius: 24px;
  padding: 0px;
}
.w-10 {
  width: 10% !important;
}
.w-20 {
  width: 20% !important;
}
.w-50 {
  width: 50% !important;
}
.w-80 {
  width: 80% !important;
}
.w-90 {
  width: 90% !important;
}
.w-100 {
  width: 100% !important;
}

.toggle-indicator {
  width: 24px;
  height: 24px;
  background: #ffffff;
  border-radius: 50%;
  transform: translateX(2px);
  transition: transform 0.1s linear;
}
.toggle-switch.on {
  background-color: #00983d;
}
.toggle-switch.on .toggle-indicator {
  transform: translateX(22px);
}
.green-color {
  color: #00983d;
}
.radio-button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid rgba(187, 189, 193, 0.5);
  background-color: transparent;
  transition: all 0.1s linear;
  cursor: pointer;
}
.pay-radio:checked + .radio-button {
  border: 1px solid #00983d;
  position: relative;
}
.radio-button::before {
  display: block;
  border-radius: 50%;
  background-color: #00983d;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.radio-button::after {
  display: block;
  border-radius: 8px;
  border: 1px solid #00983d;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}
.radio-border {
  display: block;
  border-radius: 8px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border: 1px solid transparent;
}
.pay-radio:checked + .radio-button + .radio-border {
  border: 1px solid #00983d;
  display: block;
  border-radius: 8px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}
.pay-radio:checked + .radio-button::before {
  content: "";
}
.straight-text {
  white-space: nowrap;
}
.header-height {
  height: 0px;
  padding: 0px !important;
  overflow: hidden;
  border: 0px !important;
}
.height-anim {
  transition: all 0.05s ease;
}
.icon-button {
  width: 50px;
  border: 0px;
  background-color: #00983d;
}
.corner-left {
  border-radius: 7px 0px 0px 7px;
}
.corner-right {
  border-radius: 0px 7px 7px 0px;
}
.bgc-grey {
  background-color: #f5f5f8;
}
.mbm-multiply {
  mix-blend-mode: multiply;
}
.text-module {
  width: 100%;
  overflow: hidden;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
/* MODAL CSS */
.modal-time {
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 5px 15px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}
.modal-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: #252529;
}
.modal-info {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: #252529;
}
.modal-content-title {
  font-weight: bold;
  font-size: 18.4638px;
  line-height: 22px;
  color: #000000;
}
.modal-content-info {
  font-size: 15px;
  line-height: 22px;
  color: #7e7f80;
}
.loader-spinner {
  position: absolute;
  z-index: 1 !important;
  transform: translateX(-50%);
  left: 50%;
  /* top: 325px; */
}

input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  right: 0;
  margin: 1em;
  height: inherit;
  width: initial;
  color: transparent;
  background: transparent;
}
.no-stock {
  width: 100%;
  padding: 8px;
  min-height: 35.6px;
  background-color: rgb(187, 28, 0);
  font-size: 12px;
  color: white;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
}
.no-stock-cart {
  display: inline-block;
  padding: 2px 6px;
  border: 2px solid rgb(187, 28, 0);
  /* background-color:rgb(187, 28, 0); */
  font-size: 12px;
  /* color:white; */
  color: rgb(187, 28, 0);
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
}
.price-strike {
  font-size: 13px;
  text-decoration: line-through;
  color: #757d85;
  margin-right: 8px;
}
.nav-tab {
  border-bottom: none !important;
}
.no-text-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
/* SLIDE ARROWS */
.slide-arrows {
  position: relative;
  overflow: hidden;
}
.slide-arrows .swiper-container {
  overflow: hidden;
  position: relative;
  margin: 0px 0px;
}
.slide-arrows:hover .slide-button-next,
.slide-arrows:hover .slide-button-prev {
  display: flex;
}
.slide-button-next {
  /* border-radius: 50%; */
  position: absolute;
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: all 0.3s ease;
  user-select: none;
}
.slide-button-prev {
  /* border-radius: 50%; */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: all 0.3s ease;
  user-select: none;
}
.slide-button-next,
.slide-button-prev {
  width: 40px;
  height: 40px;
  border-radius: 25%;
  background-color: mediumseagreen;
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.5)
  );
  backdrop-filter: blur(5px);
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  display: none;
  justify-content: center;
  align-items: center;
}
.slide-button-prev:active {
  transform: translateX(-5px) translateY(-50%);
}
.slide-button-next:active {
  transform: translateX(5px) translateY(-50%);
}
.arrow {
  font-size: 28px;
  color: #fff;
  cursor: pointer;
}
.slide-button-next:hover .arrow,
.slide-button-prev:hover .arrow {
  color: #000;
}
.slide-button-next.swiper-button-disabled,
.slide-button-prev.swiper-button-disabled {
  display: none !important;
}
.slide-arrows .swiper-container {
  margin: 0px 15px;
}

.full-size-fixed-spinner {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 11;
}

@media only screen and (max-width: 576px) {
  .product-list-img-mob {
    /* height: 250px !important;
    width: auto !important;
    display: inline-block !important; */
    height: 100% !important;
    object-fit: contain;
    width: 100% !important;
    display: inline-block !important;
  }
  .product-list-img-wrap {
    text-align: center;
  }
  .product-details-label {
    font-size: 1rem;
  }
  .product-list.label-green {
    font-size: 1rem;
  }
  .slide-arrows .swiper-container {
    margin: 0px 15px;
  }
  .slide-button-next,
  .slide-button-prev {
    display: none !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.lock-scroll {
  position: fixed;
  overflow-y: scroll;
}
