.details-tab {
  font-style: normal;
  font-size: 18px;
  text-transform: uppercase;
  margin-right: 15px;
  color: #777;
  font-weight: 900;
}

.active-details-tab {
  border-bottom: 3px solid #00983d;
  color: #111;
}

.nav-container {
  /* border: 1px solid #e5e5e5; */
  box-sizing: border-box;
  border-radius: 7px;
  padding: 15px;
}

.nav-tab {
  border-bottom: 0.5px solid #e5e5e5;
}

.empty-products {
  padding: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* @media only screen and (max-width: 576px) {
  .prod-list-menu {
    display: none;
  }
} */
.product-list-loader-animation {
  margin-top: 10%;
}

.cat-side-menu-head {
  font-size: 24px;
  font-weight: 900;
  padding: 5px 10px;
}
