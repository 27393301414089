.empty-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-data img {
  width: 280px;
}

.empty-data .heading {
  font-weight: bold;
  font-size: 33px;
  line-height: 54px;
  text-align: center;
  color: #000000;
}

.empty-data .description {
  font-weight: normal;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #000000;
  opacity: 0.57;
}
