.BoughtBefore_heading {
  font-size: 1.25rem;
  font-weight: 500x;
  margin-bottom: 1.5rem;
}

.bought-before-product {
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 1rem;
}

.bought-before-banner-image {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.bought-before-price {
  font-weight: bold;
  font-size: 1.25rem;
}

.bought-before-quantity {
  font-size: 0.9rem;
}

.bought-before-subtitle {
  color: #747474;
  font-size: 0.75rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.bought-before-title {
  color: #181818;
  font-size: 1rem;
  /* font-weight: 400; */
  margin-bottom: 1rem;
}

.bought-before-add-to-cart {
  background-color: #2d9140;
  color: white;
  font-size: 12px;
  border: 1px solid transparent;
}

.bought-before {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 768px) {
  .bought-before-price {
    font-weight: bold;
    font-size: 0.875rem;
  }
  .bought-before-quantity {
    font-size: 0.875rem;
    font-weight: normal;
  }
  .bought-before-subtitle {
    font-size: 0.625rem;
    margin-bottom: 0.5rem;
  }
  .bought-before-title {
    color: #181818;
    font-size: 0.875rem;
    /* font-weight: 400; */
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 425px) {
  .bought-before {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
