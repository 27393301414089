.DealsView {
  padding: 1rem 2rem 1rem 2rem;
}

.DealsView__title {
  font-size: 1rem;
  line-height: 1.5rem;

  color: #181818;
  font-weight: 700;
  /* margin: 0px 1rem; */
}

.dealsView__productName{
  overflow: hidden;
   /* text-overflow: hidden; */
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3; 
   -webkit-box-orient: vertical;

}

.DealsView__comboDeal {
  height: 100%;
  border-right: 1px solid #f2f2f2;
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.deals-view-slider {
  max-width: 70%;
  padding-left: 1rem;
}

.DealsView__subtitle {
  font-size: 0.75rem;
  font-weight: 325;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}

.DealsView__comboDeal {
  max-width: 30%;
  padding-right: 1rem;
  /* border-color: #f2f2f2; */
}

.DealsView__comboImage {
  max-width: 100%;
}

.DealsView__slider-product {
  width: 10.34rem;
  display: flex;
  margin-right: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  height: 13.5rem;
  row-gap: 0.5rem;
}

.dealsView__productName {
  font-weight: 325;
  font-size: 0.75rem;
}

.dealsView__productPrice {
  font-weight: bold;
  font-size: 0.875rem;
}

.dealsView__sliderImage {
  width: 5.7rem;
  height: 5.75rem;
}
.DealsView {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 768px) {
  .DealsView {
    padding: 1rem;
  }
  .DealsView {
    flex-direction: column;
  }
  .DealsView__title {
    margin-left: 0 !important;
    margin-top: 0rem;
  }
  .deals-view-slider {
    max-width: 100%;
    padding-left: 0;
  }

  .DealsView__subtitle {
    font-size: 0.75rem;
    font-weight: 325;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
  }

  .DealsView__comboDeal {
    max-width: 100%;
    padding-right: 0;
  }

  .DealsView__comboDeal {
    height: 100%;
    border-right: 0;
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    padding-bottom: 1rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .deals-view-slider-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 425px) {
  .DealsView {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .DealsView__slider-product {
    width: 7rem;
  }
}
