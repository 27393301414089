.address-modal-content {
    padding: 16px;
    font-family: "Gotham";
}

.address-modal-header{
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.address-modal-title{
    font-size: 16px;
    font-weight: 700;
}

.address-modal-subtitle{
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
}

.close-modal-button {
    font-family: inherit;
    font-size: 100%; 
    line-height: 1.15; 
    margin: 0;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    color:#C91713;
    padding:6px 8px;
}

.close-modal-button:hover {
    color:#C91713;
    background-color: #f8d7da;
}

.or-separator {
    color:#A3A3A3;
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.or-separator .line {
    height:1px;
    background-color: gray;
    flex-grow: 1;
    background-color: #A3A3A3;
}

.or-separator .text {
    padding: 0 8px;
    font-size: 14px;
    flex-shrink: 0;
}

.list-address-edit-modal-button {
    font-family: inherit;
    font-size: 100%; 
    line-height: 1.15; 
    margin: 0;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    display:flex;
    align-items: center;
    font-size: 14px;
    color:#747474;
    padding:6px 8px;
}

.list-address-edit-modal-button:hover {
    color:#2D9140;
}

.list-address-row-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
}

.list-address-row {
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    border-radius: 8px;
    margin-bottom: 8px;
}

.list-address-row-active {
    background-color: #EAF4EC;
}

.list-address-form-group {
    display:flex;
    align-items: center;
    cursor: pointer;
}

.list-address-radio {
    accent-color: #2D9140;
    margin-right: 16px;
    cursor: inherit;
}

.list-address-label {
    cursor: inherit;
}

.list-address-title{
    font-size: 16px;
    font-weight: 700;
    margin-right: 16px;
}

.list-address-tag{
    font-size: 14px;
    color: #2D9140;
}

.list-address-text{
    font-size: 14px;
    color: #464646;
}

.address-details-location-search {
    width:100%;
    position:relative;
}

.address-details-location-search .suggestions {
    position:absolute;
    top:100%;
    left:0;
    list-style-type: none;
    padding: 8px 0;
    width:100%;
    background-color: white;
    z-index: 500;
    border-left: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}

.address-details-location-search .suggestions .item {
    padding: 2px 8px;
    cursor: pointer;
}

.address-details-location-search .suggestions .item:hover {
    background-color: #ced4da;
}

.geosuggest__suggests--hidden {
    display:none;
}

.custom-control-input:focus~.custom-control-label::before {
    border-color: #2d9140 !important;
    box-shadow: 0 0 0 0.2rem rgba(45, 145, 64, 0.25) !important;
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: #2D9140 !important;
    background-color: #2D9140 !important;
}

.custom-control-input:active~.custom-control-label::before {
    background-color: #2D9140 !important;
    border-color: #2D9140 !important;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #2D9140 !important;
}

.custom-control-input-green:not(:disabled):active~.custom-control-label::before {
    background-color: #2D9140 !important;
    border-color: #2D9140 !important;
}

.green-accent {
    accent-color: #2D9140;
}

.delete-modal-title{
    font-size:20px;
    font-weight: 700;   
    color:#181818;
}

.delete-modal-subtitle {
    font-size:16px;
    font-weight: 700;
    color:#464646;
}

.delete-modal-text {
    font-size: 14px;
    font-weight: 300;
    color:#464646;
}

.required::after{
    content: "*";
    color: #C91713;
}