.confirm-popup-button-body {
  color: #000000;
}
.confirm-popup-button {
  font-size: 15px;
  margin: 10px;
  border: none;
  border-radius: 6px;
  padding: 5px 20px;
}

.cancel-popup-button {
  background: #ffffff;
  font-size: 15px;
  margin: 10px;
  border-radius: 6px;
  padding: 5px 20px;
}
.confirm-button-update {
  background: #00983d;
  color: #ffffff;
}

.confirm-button-delete {
  background: red;
  color: #ffffff;
}

.cancel-button-update {
  border: 1.1px solid #00983d;
  color: #00983d;
}
.cancel-button-delete {
  border: 1.1px solid red;
  color: red;
}
