.share-to-friend-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3em;
  border: 2px solid #f5f5f8;
}

.share-to-friend-container .header {
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  color: #000000;
}

.share-to-friend-container .description {
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  opacity: 0.57;
}

.share-to-friend-container .referal-code-container {
  max-width:100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #fbfbfb;
  border: 1px solid #f5f5f8;
  border-radius: 16px;
  padding: 0em 1em;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
  margin: 2em 0em;
}

.share-to-friend-container .referal-code-container .code {
  padding-right: 1rem;
  margin-bottom: 0px;
}

.share-to-friend-container .referal-code-container .copy-icon {
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.share-to-friend-container .app-icon {
  padding: 5px;
  height: 45px;
  width: 45px;
}

.share-to-friend-container .code-display {
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.share-to-friend-container .share-btn {
  background: #00983d;
  border-radius: 8.83744px;
  outline: none;
  border: none;
  padding: 7px 24px;
  margin: 1rem 0px;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}
