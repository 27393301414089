.hurry-items-sidebar {
  position: absolute;
  bottom: 5px;
  right: 24px;
  font-size: 10px;
  font-weight: 600;
  color: tomato;
}
@media screen and (max-width: 768px) {
  .side-cart-price {
    font-size: 14px;
  }
}
