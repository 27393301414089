.order-status-icon-box {
  border-radius: 8px;
  width: 100px !important;
  height: 100px !important;
}

.order-status-success {
  border: 3px solid #00983d !important;
}

.order-status-waiting {
  border: 3px solid #7f8fa4 !important;
}

.order-status-failed {
  border: 3px solid #f16c70 !important;
}

.order-status-in-transit {
  border: 3px solid #9fb7e7 !important;
}

.order-status-packed {
  border: 3px solid #ebc871 !important;
}

.order-status-head {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.order-status-details,
.order-status-no-items {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.order-status-no-items {
  color: #7e7f80;
}

.order-status-total-amount {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}
