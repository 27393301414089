.homepage-new {
  font-family: "Gotham";
}

.circularDiv2 {
  height: 80px;
  width: 80px;
  background-color: #8a2be2;
  border-radius: 100%;
  border: 3px solid pink;
  background: url("https://im.whatshot.in/img/2020/Mar/untitled-design-9-1584085932.jpg");
  margin-top: 20px;
  margin-left: 65px;
  background-size: contain;
}
.circularDiv3 {
  height: 80px;
  width: 80px;
  background-color: #8a2be2;
  border-radius: 100%;
  border: 3px solid pink;
  background: url("https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/gh-best-skincare-products-1628607809.png?crop=1.00xw:1.00xh;0,0&resize=1200:*");
  margin-top: 20px;
  margin-left: 65px;
  background-size: contain;
}
.circularDiv4 {
  height: 80px;
  width: 80px;
  background-color: #8a2be2;
  border-radius: 100%;
  border: 3px solid pink;
  background: url("https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/gh-best-skincare-products-1628607809.png?crop=1.00xw:1.00xh;0,0&resize=1200:*");
  margin-top: 20px;
  margin-left: 65px;
  background-size: contain;
}
.circularDiv5 {
  height: 80px;
  width: 80px;
  background-color: #8a2be2;
  border-radius: 100%;
  border: 3px solid pink;
  background: url("https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/gh-best-skincare-products-1628607809.png?crop=1.00xw:1.00xh;0,0&resize=1200:*");
  margin-top: 20px;
  margin-left: 65px;
  background-size: contain;
}
.circularDiv6 {
  height: 80px;
  width: 80px;
  background-color: #8a2be2;
  border-radius: 100%;
  border: 3px solid pink;
  background: url("https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/gh-best-skincare-products-1628607809.png?crop=1.00xw:1.00xh;0,0&resize=1200:*");
  margin-top: 20px;
  margin-left: 65px;
  background-size: contain;
}
.circularDiv7 {
  height: 80px;
  width: 80px;
  background-color: #8a2be2;
  border-radius: 100%;
  border: 3px solid pink;
  background: url("https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/gh-best-skincare-products-1628607809.png?crop=1.00xw:1.00xh;0,0&resize=1200:*");
  margin-top: 20px;
  margin-left: 65px;
  background-size: contain;
}
.circularDiv8 {
  height: 80px;
  width: 80px;
  background-color: #8a2be2;
  border-radius: 100%;
  border: 3px solid pink;
  background: url("https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/gh-best-skincare-products-1628607809.png?crop=1.00xw:1.00xh;0,0&resize=1200:*");
  margin-top: 20px;
  margin-left: 65px;
  background-size: contain;
}
.wrapper {
  display: flex;
}

/* .carousel {
  margin: 100px;
} */

.offerCard {
  height: 200px;
  width: 400px;
  background-color: red;
  border-radius: 10px;
  margin-left: 20px;
}
.cardWrapper {
  display: flex;
}
.title {
  height: 150px;
  display: flex;

  align-items: center;
}
.button-new {
  background-color: #4caf50;
  border: none;
  color: white;
  /* text-align: center; */
  /* text-decoration: none; */
  /* display: inline-block; */
  /* font-size: 16px; */
  /* margin: 4px 2px; */
  cursor: pointer;
  /* padding: 0.5rem 1rem; */
  /* height: 40px; */
  /* margin-left: 30px !important; */
}
.bigEssentialCard {
  background: linear-gradient(to bottom, #66ccff 0%, #ff99cc 100%);
  height: 400px;
  width: 650px;
  border-radius: 10px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.smallEssentialCard {
  background: linear-gradient(to bottom, #66ccff 0%, #ffff99 100%);
  height: 190px;
  width: 550px;
  border-radius: 10px;
  margin-left: 20px;
  margin-bottom: 10px;
}
.essentialWrapper {
  display: flex;
}
.smallEssentialWrapper {
  display: flex;
  flex-direction: column;
}
.product_wrapper {
  display: flex;
  flex-direction: column;
  height: 390px;
  width: 500px;
  margin-left: 20px;
}
.product_name {
  color: white;
  font-size: 80px;
  font-weight: bold;
}
.essentialButton {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  padding: 10px 30px;
  height: 40px;
  width: 250px;
  margin-left: 30px !important;
  margin-top: 60px;
}
.product_name_small {
  color: black;
  font-size: 35px;
  font-weight: bold;
  width: 300px;
  height: 70px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-left: 20px;
}
.essentialButton_small {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  padding: 10px 30px;
  height: 40px;
  margin-left: 30px !important;
}
.halfWidthDiscount {
  width: 600px;
  height: 100px;
  background: linear-gradient(to bottom, #333300 0%, #66ff99 100%);
  border-radius: 10px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.halfWidthWrapper {
  display: flex;
}
.halfWidthTitle {
  height: 100px;
  width: 400px;
  display: flex;
  align-items: center;
}
.discountTitle {
  padding: 20px;
}
.homepage_title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
.full-width-wrapper {
  width: 98%;
  margin: auto;
}
.main-content-wrapper {
  /* width: 98%; */
  padding: 2rem;
  margin: auto;
  max-width: 1440px;
}

.rounded {
  border-radius: 0.6rem;
}

/* swipe ARROWS */
.swipe-arrows {
  position: relative;
  overflow: hidden;
}
.swipe-arrows .swiper-container {
  overflow: hidden;
  position: relative;
  margin: 0px 0px;
}
.swipe-arrows:hover .swipe-button-next,
.swipe-arrows:hover .swipe-button-prev {
  display: flex;
}
.swipe-button-next {
  /* border-radius: 50%; */
  position: absolute;
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: all 0.3s ease;
  user-select: none;
}
.swipe-button-prev {
  /* border-radius: 50%; */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: all 0.3s ease;
  user-select: none;
}
.swipe-button-next,
.swipe-button-prev {
  width: 40px;
  height: 100px;
  border-radius: 0 !important;
  background-color: #ffffff;
  background-image: grey;
  backdrop-filter: blur(5px);
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px #888;
  display: none;
  justify-content: center;
  align-items: center;
}
.swipe-button-prev:active {
  transform: translateX(-5px) translateY(-50%);
}
.swipe-button-next:active {
  transform: translateX(5px) translateY(-50%);
}
.swipe-arrow {
  font-size: 28px;
  color: #333333;
  cursor: pointer;
}
.swipe-button-next:hover .swipe-arrow,
.swipe-button-prev:hover .swipe-arrow {
  color: #333333;
}
.swipe-button-next.swiper-button-disabled,
.swipe-button-prev.swiper-button-disabled {
  display: none !important;
}
.swipe-arrows .swiper-container {
  margin: 0px 15px;
}

/* CUSTOM BORDER RADIUS */

.r-025 {
  border-radius: 0.25rem !important;
}
.r-05 {
  border-radius: 0.5rem !important;
}
.r-075 {
  border-radius: 0.75rem !important;
}
.r-1 {
  border-radius: 1rem !important;
}
.r-125 {
  border-radius: 1.25rem !important;
}
.r-15 {
  border-radius: 1.5rem !important;
}
.r-175 {
  border-radius: 1.75rem !important;
}
.r-2 {
  border-radius: 2rem !important;
}
/* @media only screen and (max-width: 768px) {
  .main-content-wrapper {
    width: 95%;
    margin: auto;
    max-width: 1376px;
  }
} */

@media only screen and (max-width: 1024px) {
  .homepage_title {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 768px) {
  .homepage_title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.75rem;
  }
}
@media only screen and (max-width: 425px) {
  .homepage_title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.75rem;
  }
}
@media only screen and (max-width: 375px) {
}
@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 576px) {
  .product-list-img-mob {
    height: 120px !important;
    width: auto !important;
    display: inline-block !important;
  }
  .product-list-img-wrap {
    text-align: center;
  }
  .product-details-label {
    font-size: 1rem;
  }
  .product-list.label-green {
    font-size: 1rem;
  }
  .swipe-arrows .swiper-container {
    margin: 0px 15px;
  }
  .swipe-button-next,
  .swipe-button-prev {
    display: none !important;
  }
}
