.inaam-app {
    background: linear-gradient(44.64deg, #C51714 0%, #C51714 47.76%, #8A1714 100.65%);
    max-height: 100vh;
    width:100%;
}

.inaam-app .vector {
    background-image: url('../../assets/images/inaam-app-vector.svg');
    background-blend-mode: multiply;
    mix-blend-mode: multiply;
    width:100%;
    position:absolute;
    height:100%;
}

.inaam-app .x-link {
    color:white;
    position: absolute;
    top:10px;
    right:10px;
    font-size: 24px;
}

.inaam-app .x-link:hover {
    text-decoration: none;
}

.inaam-app .content {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    position: relative;
}

.inaam-app .content .box-one {
    padding-top:32px;
    margin-bottom: 32px;
}

.inaam-app .content .box-one .message-box {
    max-width: 700px;
}

.inaam-app .content .box-one img {
    width:100%;
    max-width: 272px;
}

.inaam-app .content .box-one .message-box .heading{
    font-size: 24px;
    line-height: 40px;
    font-weight: 500;
    margin-bottom: 16px;
    color:#FFFFFF;
}

.inaam-app .content .box-one .message-box .details{
    font-size: 48px;
    line-height: 60px;
    font-weight: 800;
    color:#FFFFFF;
}

.inaam-app .content .box-two {
    flex-grow: 1;
}

.inaam-app .content .box-two .link-box {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    row-gap: 16px;
    flex-grow: 1;
}

.inaam-app .content .box-two .image-box {
    display:flex;
    align-items: flex-end;
    justify-content: end;
}

.inaam-app .content .box-two .image-box img {
    width:100%;
}

.inaam-app .content .box-two .image-box .first-image {
    margin-right: 32px;
}

.inaam-app .content .box-two .details-box {
    background-color: #8B1200;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
    padding:24px;
    width: 100%;
    max-width:374px;
    height:264px;
    text-align: center;
    white-space: nowrap;
}

.inaam-app .content .box-two .details-box .heading {
    font-size: 24px;
    line-height: 40px;
    font-weight: 500;
    margin-bottom: 24px;
    color:#FFFFFF;
}

@media screen and (max-width:992px) {
    .inaam-app {
        max-height: max-content;
    }

    .inaam-app .content {
        height:auto;
    }

    .inaam-app .content .box-one {
        margin-bottom:0;
        padding: 0 32px;
    }

    .inaam-app .content .box-one img {
        height:auto;
        width:160px;
        padding:32px 32px;
    }

    .inaam-app .content .box-one .message-box {
        text-align: center;
    }

    .inaam-app .content .box-two .link-box {
        flex-direction: row;
        margin-bottom:64px;
        row-gap: 0;
        column-gap: 16px;
        padding: 0 32px;
    }

    .inaam-app .content .box-two .details-box {
        padding: 16px;
    }

    .inaam-app .content .box-one .message-box .heading{
        font-size: 16px;
        line-height: 24px;
    }
    
    .inaam-app .content .box-one .message-box .details{
        font-size: 24px;
        line-height: 40px;
    }
}

@media screen and (max-width:576px) {
    .inaam-app .content .link-box .qr-box {
        display:none;
    }

    .inaam-app .content .box-one .message-box {
        text-align: left;
    }

    .inaam-app .content .box-two .details-box {
        height: auto;
        white-space: normal;
    }

    .inaam-app .content .box-two .details-box img {
        max-height:64px;
        max-width: 100%;
    }
}