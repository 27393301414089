.main-loader-container {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.main-loader-animation {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 10%;
}
