.GridView_wrapper {
  /* width: 100%; */
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: -1rem;
  padding-left: -1rem;
}

.GridView_heading {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
}

.gap {
  row-gap: 1rem;
  column-gap: 1rem;
}

.grid-view-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
}

.GridView__EndTime {
  font-size: 0.75rem;
  font-weight: bold;
  color: white;
  margin-left: 0.5rem;
}

.GridView {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.grid-wrapper-mobile {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(3, 1fr); */
  gap: 0.25rem;
}
@media (max-width: 1180px) {
  .grid-view-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
}

@media (max-width: 900px) {
  .grid-view-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
}

@media only screen and (max-width: 764px) {
  .GridView__EndTime {
    font-weight: bold;
    font-size: 0.75rem;
  }
  .GridView_heading {
    font-size: 1rem;
    font-weight: 500;
  }
  .GridView_wrapper {
    padding-bottom: 1rem !important;
  }
}

@media only screen and (max-width: 425px) {
  .GridView {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}

@media only screen and (max-width: 382px) {
  .GridView__EndTime {
    font-weight: bold;
    font-size: 0.5rem;
  }
  .GridView_heading {
    font-size: 1rem;
    font-weight: 500;
  }
}

/* SWIPPER */
.shop-by-cat .swiper-container {
  margin: 0px;
}
.shop-by-cat-title {
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 25px;
  margin-left: 15px;
}
.shop-by-cat .swiper-slide {
  display: block;
  width: auto;
}
.shopbycat-slide {
  display: flex;
  gap: 15px;
  width: 330px;
  max-width: 100%;
  flex-wrap: wrap;
  font-family: "Poppins", sans-serif;
  margin-right: 10px;
}
.shopbycat-box:first-child {
  width: 330px;
  height: 100px;
}
.shopbycat-box {
  width: 100px;
  height: 100px;
  font-size: 10px;
  transition: all 0.1s ease;
  cursor: pointer;
  user-select: none;
  background: #f5f5f8;
  border-radius: 8px;
}
.shopbycat-box:hover {
  transform: scale(0.97);
}
.shopbycat-box-img {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .shopbycat-box:first-child {
    width: 240px;
    height: 70px;
  }
  .shopbycat-box {
    width: 70px;
    height: 70px;
  }
}
