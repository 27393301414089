.modal-popup .modal-dialog{
  max-width: 650px !important;
  width:100% !important;
}
.modal-anim .modal-dialog {
  animation: modal-animation 0.2s ease-in-out !important;
  transform: translateY(0px);
}
.modal-backdrop {
  animation: modal-backdrop-anim 0.2s ease-in-out !important;
}
.modal-primary-btn {
  background-color: #00983d;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #ffffff;
  padding: 9px 20px;
  border: 0px;
}
@keyframes modal-animation {
  0% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes modal-backdrop-anim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
