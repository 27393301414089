.address-book {
  font-family: "Gotham";
}

.edit-btn-addr {
  background-color: #00983d;
  border: none;
  border-radius: 6px;
  outline: none;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 14.1483px;
  line-height: 17px;
  text-align: center;
  padding: 5px;
  margin: 5px;
  min-width: 100px;
  min-height: 30px;
}

.delete-btn-addr {
  border: 1.18462px solid #c71712;
  border-radius: 6.98619px;
  background: none;
  border-radius: 6px;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14.1483px;
  line-height: 17px;
  text-align: center;
  padding: 5px;
  margin: 5px;
  color: #c71712;
  min-width: 100px;
  min-height: 30px;
}
.address-book-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}
