.cookies {
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: 750px;
  left: 50%;
  transform: translateX(-50%) translateY(0%);
  background: #ffffff;
  box-shadow: 1px -1px 20px rgba(178, 178, 178, 0.5);
  padding: 20px;
  font-size: 14px;
  transition: all 0.3s ease;
  z-index: 999;
}
.cookies-cancelled {
  transform: translateX(-50%) translateY(110%);
  display: none;
}
.cookie-img {
  height: 40px;
  margin-right: 10px;
}
.cookie-text {
  max-width: 350px;
  margin: 0px;
}
.cookie-accept {
  color: white;
  background: #249140;
  border-radius: 5.57833px;
  padding: 8px 20px;
  border: 0px;
  margin-right: 20px;
}
.cookie-close {
  color: #c71712;
  border-radius: 5.57833px;
  padding: 8px 36px;
  border: 0px;
  background-color: transparent;
  border: 1px solid #c71712;
}
