.two-grid-wrapper {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
.offer-title {
  margin-bottom: 0.5rem;
  font-size: 2rem;
  font-weight: 700;
  /* margin-bottom: 0; */
}
.banner-button {
  /* margin-left: 0 !important; */
  border: none;
  border-radius: 0.3rem;
  width: 156px;
  height: 40px;
}
.main-heading {
  font-size: 1rem;
  font-weight: bold;
}
.banner-images {
  /* height: 199px; */
}

.offer-btn-wrapper {
  height: 100%;
}
/* .banner-v2-images {
  width: 10%;
} */
.offer-btn-wrapper {
}
.grid-wrapper-1 {
  /* width: 100%; */
  padding: 2rem 1.5rem 2rem 1.5rem;
  border-radius: 0.5rem;
}
.grid-wrapper-1-v2 {
  /* width: 100%; */
  padding: 1rem;
  border-radius: 0.5rem;
}
.grid-wrapper-2 {
  /* width: 100%; */
  padding: 2rem;
  border-radius: 0.5rem;
}
.banner-image-v2-discount-wrapper {
  font-size: 3rem;
  font-weight: 700;
  position: relative;
}

.banner-image-v2-percent {
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  right: -1rem;
  top: 0.5rem;
}
.banner-image-v2-off {
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  right: -1.8rem;
  bottom: 0.5rem;
}

.banner-image-v2-title {
  font-weight: 325;
  font-size: 1rem;
}

.banner-image-v2-original-price {
  font-weight: 325;
  font-size: 0.875rem;
  margin-top: 1rem;
  text-decoration-line: line-through;
}

.banner-image-v2-discounted-price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #191c27;
  margin-bottom: 0.5rem;
}

.swiper-slide {
  height: auto;
}
/* .banner-image-v2-discount-wrapper {
  font-size: 2rem;
  font-weight: 700;
  position: relative;
}

.banner-image-v2-percent {
  font-size: 0.75rem;
  font-weight: bold;
  position: absolute;
  right: -1rem;
  top: 0.5rem;
}
.banner-image-v2-off {
  font-size: 0.75rem;
  font-weight: bold;
  position: absolute;
  right: -1.2rem;
  bottom: 0.3rem;
}

.banner-image-v2-title {
  font-weight: 325;
  font-size: 0.75rem;
}

.banner-image-v2-original-price {
  font-weight: 325;
  font-size: 0.5rem;

  text-decoration-line: line-through;
}

.banner-image-v2-discounted-price {
  font-size: 0.75rem;
  font-weight: bold;
  color: #191c27;
} */
.banner-v2-images {
  max-width: 142.21px;
}

.banner-btn-v2 {
  font-size: 0.875rem;
  margin-top: 0.5rem;
  border: none;
  width: 174px;
  background: #2d9140;
  font-weight: bold;
  font-size: 14px;
  color: white;
  height: 40px;
}

.two-banner-label {
  height: 1.845rem;
}

.two-banner-label-text {
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  top: 5px;
  right: 8%;
  color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .offer-title {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    font-weight: bold;
  }
  .banner-button {
    margin-left: 0 !important;
    font-size: 0.75rem;
    font-weight: 700;
    width: 120px;
    height: 30px;
    margin-top: 1rem;
  }
  .banner-images {
    height: 3.5rem;
    width: 3.5rem;
  }

  .grid-wrapper-1 {
    /* width: 100%; */
    padding: 0.8rem;
    border-radius: 0.2rem;
  }

  .grid-wrapper-1-v2 {
    /* width: 100%; */
    padding: 0.5rem;
    border-radius: 0.2rem;
  }

  .grid-wrapper-2 {
    /* width: 100%; */
    padding: 0.8rem;
    border-radius: 0.2rem;
  }
  .banner-v2-images {
    width: 52%;
  }
  .banner-image-v2-discount-wrapper {
    font-size: 2rem;
    font-weight: 700;
    position: relative;
  }

  .banner-image-v2-percent {
    font-size: 0.75rem;
    font-weight: bold;
    position: absolute;
    right: -1rem;
    top: 0.5rem;
  }
  .banner-image-v2-off {
    font-size: 0.75rem;
    font-weight: bold;
    position: absolute;
    right: -1.2rem;
    bottom: 0.3rem;
  }

  .banner-image-v2-title {
    font-weight: 325;
    font-size: 0.75rem;
  }

  .banner-image-v2-original-price {
    font-weight: 325;
    font-size: 0.65rem;

    text-decoration-line: line-through;
  }

  .banner-image-v2-discounted-price {
    font-size: 1rem;
    font-weight: bold;
    color: #191c27;
  }
  .two-banner-label {
    height: 1.5rem;
  }
  .two-banner-label-text {
    font-weight: bold;
    font-size: 10px;
  }
}

@media only screen and (max-width: 426px) {
  .offer-title {
    font-size: 0.75rem;
  }
  .banner-button {
    font-size: 0.6rem;
    font-weight: 600;
    width: 87px;
    height: 24px;
  }
  .grid-wrapper-1 {
    /* width: 100%; */
    padding: 0.75rem 1rem 0.75rem 1rem;
    border-radius: 0.5rem;
  }
  .two-banner-label {
    height: 1.25rem;
  }
}
@media only screen and (max-width: 375px) {
  .offer-title {
    font-size: 0.7rem;
  }
  .banner-button {
    font-size: 0.5rem;
    font-weight: 600;
    width: 60px;
    height: 20px;
  }
  .grid-wrapper-1 {
    /* width: 100%; */
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    border-radius: 0.5rem;
  }

  .banner-image-v2-original-price {
    font-size: 0.7rem;
  }
}
