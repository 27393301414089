/* .offer-banner-image {
} */

/* .offer-banner-title {
  font-size: 3rem;
  margin-left: 1rem;
} */
.offer-banner-image {
  /* width: 23%; */
  height: auto;
  max-height: 95px;
  /* margin: 0.5rem 2rem 0.5rem auto; */
}

.OfferBanner {
  padding: 0.625rem 2rem 0.625rem 2rem;
  margin-top: 1rem;
}

.offer-banner-title {
  font-weight: 325;
  font-size: 1rem;
  color: #d1d1d1;
}

.offer-banner-discount {
  font-weight: bold;
  font-size: 2rem;
  color: #ffffff;
  /* padding-left: 1rem; */
}

.offer-banner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 1024px) {
  .offer-banner-title {
    font-size: 1.25rem;
  }
  /* .OfferBanner {
    padding: 1rem;
    margin-top: 0.1rem;
  } */
  .offer-banner-image {
    height: auto;
    max-height: 100px;
  }
}
@media (max-width: 768px) {
  .offer-banner-title {
    font-size: 1rem;
  }
  /* .OfferBanner {
    padding: 1rem;
    margin-top: 0.1rem;
  } */

  .offer-banner-discount {
    font-size: 1.35rem;
  }
  .offer-banner-image {
    height: auto;
    max-height: 80px;
  }
}
@media (max-width: 460px) {
  .offer-banner-title {
    font-size: 0.75rem;
  }

  .OfferBanner {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    margin-top: 0.5rem;
  }

  .offer-banner-image {
    width: 42%;
  }
  .offer-banner-discount {
    font-size: 1.25rem;
  }
}
@media (max-width: 320px) {
  .offer-banner-title {
    font-size: 0.75rem;
  }
}
