.order-item-container {
  background-color: #f5f5f8;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.order-item-image-container {
  max-height: 60px;
  position: relative;
}
.order-item-qty {
  background-color: white;
  padding: 5px 10px;
  color: red;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px;
  position: absolute;
  bottom: 5px;
}
.order-item-name {
  word-break: break-all !important;
}
.order-item-price {
  color: mediumseagreen;
  min-width: 75px;
  text-align: right;
  font-weight: bold;
}
