.three-banner-grid-heading {
  font-size: 1rem;
  font-weight: bold;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 1rem;
}
.full-width-banner {
  grid-area: 1 / 1 / 2 / 2;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2.625rem;
  padding-bottom: 2.625rem;
  overflow: hidden;
  /* padding: 0 !important; */
}
.half-width-banner-1 {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow: hidden;
}

.half-width-banner-2 {
  grid-area: 1 / 3 / 2 / 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow: hidden;
}

.cta_btn {
  border: none;
  padding: 0.5rem 2rem !important;
  font-weight: bold;
  color: white;
  font-size: 0.875rem;
  border-radius: 0.5rem !important;
  margin-left: 0 !important;
  border-radius: 0.375rem;
}

.half-banner-cta-btn {
  /* padding: 0.5rem 2.8rem 0.5rem 2.8rem; */
  width: 106px;
  height: 48px;
}

.full-banner-cta-btn {
  /* padding: 0.7rem 6.9rem 0.7rem 6.9rem; */
  width: 106px;
  height: 48px;
  /* margin-top: 2rem; */
}

.banner-image {
  max-width: 13.625rem;
  padding-right: 1.5rem;
  width: 43%;
}
.full-banner-image {
  max-height: 264px;
  margin-right: 3rem;
  width: 30%;
}

.heading-button-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 1.5rem;
  justify-content: space-evenly;
  align-items: flex-start;
}
.full-banner-heading-button-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 2rem;
}

.full-banner-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.half-banner-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.ThreeGridBanner {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 1080px) {
  .full-banner-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .full-banner-image {
    /* max-width: 50%; */
    margin-right: 2rem;
    width: 30%;
  }

  .half-banner-title {
    font-size: 1.5rem;
  }

  .half-banner-cta-btn {
    /* padding: 0.5rem 1.3rem 0.5rem 1.3rem; */
  }

  .full-banner-cta-btn {
    /* padding: 0.7rem 5rem 0.7rem 5rem; */
  }

  .banner-image {
    padding-right: 0.5rem;
    width: 53%;
  }
}

@media (max-width: 768px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 0.5rem;
  }
  .full-banner-heading-button-wrapper {
    margin-left: 1rem;
  }
  .full-width-banner {
    grid-area: 1 / 1 / 3 / 3;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .half-width-banner-1 {
    grid-area: 3 / 1 / 5 / 2;
  }

  .half-width-banner-2 {
    grid-area: 3 / 2 / 5 / 3;
  }
  .banner-title {
    font-size: 0.7rem;
  }
  .banner-image {
    width: 53%;
  }

  /* .grid-container {
    margin-bottom: -25%;
  } */
  .cta_btn {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important ;
    border-radius: 0.5rem !important;
    font-weight: 500 !important;
    font-size: 2rem;
    padding-bottom: 0.1rem;
    padding-top: 0.1rem;
  }
  .heading-button-wrapper {
    margin-left: 0.5rem;
  }
}

@media (max-width: 600px) {
  .full-banner-title {
    font-size: 1.875rem;
    font-weight: bold;
  }
  .half-banner-title {
    font-size: 1rem;
  }
}

@media (max-width: 425px) {
  .banner-title {
    font-size: 1rem;
  }
  /* .banner-image {
    width: 70% !important;
  } */
  .cta_btn {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    border-radius: 0.5rem !important;
    font-weight: 500 !important;
    font-size: 2rem;
    padding-bottom: 0.1rem;
    padding-top: 0.1rem;
  }
  .heading-button-wrapper {
    margin-left: 0.5rem;
  }
  .full-banner-title {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .half-banner-title {
    font-size: 0.75rem;
  }
  .ThreeGridBanner {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 375px) {
  .full-banner-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .half-banner-title {
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 320px) {
  .full-banner-title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .half-banner-title {
    font-size: 0.65rem;
  }
}
