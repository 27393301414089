.notification-item {
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #eee;
  height:100%;
}
.notification-title {
  padding-right: 30px;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  position: relative;
}
.unread {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 0px;
  right: 10px;
}
.unread::after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  border: 1px solid red;
  background-color: transparent;
  transform: translate(-50%, -50%);
}
.notification-content {
  font-size: 14px;
  color: #444;
  margin-bottom: 5px;
  transition: all 0.15s linear;
  overflow: hidden;
}
.notification-time {
  font-size: 12px;
  color: #555;
  margin: 0px;
}
.spinner-arrow {
  transition: all 0.15s linear;
}
.spinner-arrow.spin180 {
  transform: rotate(-180deg);
}
