.button-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-spinner {
  height: 20px;
  padding: 10px;
  border: 2px solid #28a745;
  border-radius: 50%;
  border-top: 2px solid #ffffff;
  border-left: 1px solid;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
