.status-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.status-heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
}

.status-details {
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  opacity: 0.57;
}
