.status-container {
  border-radius: 7px;
  border: 2px solid #f5f5f8;
  padding: 30px 50px !important;
  margin: 0 10px !important;
}

.address-view-container {
  border-radius: 7px;
  border: 2px solid #f5f5f8;
  margin: 0 10px !important;
}

.address-view-header {
  border-bottom: 2px solid #f5f5f8;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
}

.address-view-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 0 !important;
}

.address-view-order {
  font-style: normal;
}

.order-title {
  font-size: 16px;
  font-weight: normal;
  color: #666666;
}

.order-total-title,
.order-total-value {
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}

.order-value {
  color: #000000;
}

.payment-credit-card {
  font-weight: 500;
  font-size: 20px;
  color: #010202;
}
