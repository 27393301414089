.cat-side-menu-container {
  border: 2px solid #f5f5f8;
  border-radius: 10px;

  /* height: 100%; */
}

.cat-side-menu-body-container {
  padding: 0px 10px 50px 10px;
  font-weight: 700;
}

.cat-side-menu-item:hover {
  color: mediumseagreen;
}
.cat-side-menu-mega {
  border-bottom: 2px solid #f5f5f8;
  padding: 8px 0px;
  font-size: 16px;
}
.cat-side-menu-main {
  font-size: 14px;
  padding: 6px 0px;
}
.cat-side-menu-sub {
  padding: 4px 0px;
  font-size: 13px;
}
