.location-web {
  font-family: "Gotham";
}

.location-page-padding{
  padding-left:25px !important;
  padding-right:25px !important;
}

.hero-image{
  width:100%;
  min-height: 350px;
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center center;  
}

.location-banner {
  width:100%;
  max-width: 640px;
  text-align: center;
  margin-top: -180px;
  background-color: white;
  padding: 32px 64px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  z-index: 10;
  margin-bottom: 64px;
}

.location-banner .heading {
  font-size: 48px;
  font-weight: 700;
  color:#181818;
}

.location-banner .sub-heading {
  font-size: 16px;
  font-weight: 400;
  color:#464646;
  margin-bottom: 24px;
}

.service-details{
  display:grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 40px;
  text-align: center;
  margin-bottom: 64px;
}

.service-item img{
  margin-bottom: 12px;
}

.service-item .heading{
  font-size: 16px;
  font-weight: 700;
  color:#464646;
}

.service-item .details{
  font-size: 14px;
  font-weight: 400;
  color:#464646;
}

.service-tiles {
  margin-bottom: 64px;
}

.service-tiles .heading {
  color:#181818;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 32px;
}

.service-tiles .image-grid {
  display:flex;
  align-items: start;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 32px;
  column-gap: 16px;
}

.service-tiles .image-grid .item {
  width: fit-content;
}

.service-tiles .image-grid .item img {
  width: 100%;
  margin-bottom: 16px;
}

.service-tiles .image-grid .item .image-grid-title {
  color:#464646;
  font-size: 16px;
  font-weight: 700;
}

.inaam-banner{
  padding: 40px 48px;
  background:linear-gradient(90deg, #FBDBD9 0%, rgba(250, 244, 235, 0.5) 84.38%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
  border-radius: 8px;
  margin-bottom: 64px;
}

.inaam-banner .heading {
  font-size: 48px;
  color:#191C27;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: 60px;
}

.inaam-banner .sub-heading {
  font-size: 24px;
  font-weight: 500;
  color:#464646;
  margin-bottom: 16px;
  line-height: 40px;
}

.inaam-banner .details {
  font-weight: 325;
  font-size: 16px;
  color:#464646;
  margin-bottom: 16px;
  line-height: 24px;
}

.inaam-banner img {
  width:100%;
  max-width: 282px;
}

.nesto-app-banner {
  background: linear-gradient(261.68deg, #DAFBE1 6.38%, rgba(255, 255, 255, 0.33125) 91.88%);
  margin-bottom: 64px;
  padding: 0 24px;
  border-radius: 8px;
}

.nesto-app-banner .image-col {
  padding-top:40px;
}

.nesto-app-banner .image-col img {
  width:50%;
  max-width: 245px;
}

.nesto-app-banner .content-col {
  display:flex;
  align-items: center;
}

.nesto-app-banner .badge {
  padding: 4px 12px;
  background: #C91713;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 800;
  color:white;
}

.nesto-app-banner .heading {
  font-size: 32px;
  line-height: 40px;
  color:#191C27;
  font-weight: 700;
  margin-bottom:16px;
}

.nesto-app-banner .details {
  font-size: 16px;
  line-height: 24px;
  color:#464646;
  font-weight: 325;
  margin-bottom:16px;
}

/* .faq-section{
  background-color:#EAF4EC;
  padding:32px;
  margin-bottom: 64px;
  border-radius: 8px;
}

.faq-section .heading {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  color:#2D9140;
  margin-bottom: 6px;
}

.faq-section .que-ans {
  margin-bottom: 16px;
}

.faq-section .que-ans .que {
  font-size: 20px;
  line-height: 32px;
  color:#181818;
  font-weight: 500;
  margin-bottom: 4px;
}

.faq-section .que-ans .ans {
  font-size: 16px;
  line-height: 24px;
  font-weight: 325;
  color:#464646;
}

.faq-section .contact {

}

.faq-section .contact-que {
  font-size: 16px;
  font-weight: 700;
  color:#464646;
  line-height: 24px;
  margin-bottom: 8px;
}

.faq-section .contact-ans {
  font-size: 14px;
  font-weight: 325;
  color:#464646;
  line-height: 22px;
  margin-bottom: 8px;
} */

.location-mobile {
  display:none;
}

@media screen and (max-width:992px) {
  .location-banner {
    margin-top:-80px
  }

  .nesto-app-banner .image-col img {
    width:100%;
  }

  .service-details{
    grid-template-columns: repeat(6, 1fr);
    row-gap: 32px;
  }

  .two-span {
    grid-column: span 2;
  }

  .three-span {
    grid-column: span 3;
  }
}

.btn-white {
  background-color: white !important;
  border:none !important;
}

.btn-white:hover{
  background-color: #f8f9fa !important;
  border:none !important;
}

@media screen and (max-width:768px) {
  .location-web {
    display:none;
  }

  .location-mobile {
    display:block;
  }

  .location-mobile .mob-header {
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding:16px;
    height:88px;
  }

  .location-mobile .mob-hero {
    height:450px;
    width:100%;
    background-color: #A0D2A9;
  }

  .location-mobile .mob-hero .slide {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100%;
    padding: 16px;
  }

  .location-mobile .mob-hero .slide img {
    width:100%;
    max-width: 310px;
  }

  .location-mobile .mob-hero .slide .details {
    font-size: 24px;
    line-height: 40px;
    color: #181818;
    font-weight: 500;
    text-align: center;
    margin-top:16px;
  }

  .location-mobile .mob-location-banner {
    background-color: white;
    box-shadow: inset 0px 1px 0px #E0E0E0;
    border-radius: 12px 12px 0px 0px;
    padding:16px;
    text-align: center;
    margin-top:-8px;
  }

  .location-mobile .mob-location-banner .heading {
    font-size: 32px;
    line-height: 40px;
    color: #181818;
    font-weight: 700;
  }

  .location-mobile .mob-location-banner .details {
    font-size: 16px;
    line-height: 24px;
    color: #464646;
    font-weight: 325;
  }

  .location-mobile .mob-location-banner .helper {
    font-size: 16px;
    line-height: 24px;
    color: #747474;
    font-weight: 325;
  }

  .location-mobile .mob-location-banner .helper-link {
    font-size: 16px;
    line-height: 24px;
    color: #2D9140;
    font-weight: 325;
  }
}