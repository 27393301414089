.progress-wrap-outer {
  transition: all 0.2s linear;
}
.progress-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.progress-item {
  width: 30px;
  height: 30px;
  border: 2px solid #ccc;
  border-radius: 50%;
  position: relative;
  margin-right: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
}
.progress-item:last-child {
  margin-right: 0px;
  margin-bottom: 0px;
}
.progress-text {
  color: #333;
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  top: 38px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  white-space: nowrap;
}
.progress-text-status {
  color: #777;
  font-size: 11px;
}
.progress-item:last-child::after,
.progress-item:last-child::before {
  display: none;
}
.progress-item::after,
.progress-item::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -115px;
  transform: translateY(-50%);
  display: block;
  width: 100px;
  height: 2px;
  background-color: #ccc;
}
.progress-item::before {
  z-index: 1;
}
.progress-item.complete {
  border: 2px solid #00983d;
  color: #00983d;
}
.progress-item.complete::before {
  background: #00983d;
  width: 100px;
  right: -115px;
}
.progress-item.current {
  border: 0px;
  background: linear-gradient(to right, #01c44f, #00983d);
  animation: progress 3s ease infinite;
  background-size: 400% 400%;
  color: white;
}
.progress-item.next::before {
  background: #00983d;
  width: 50px;
  right: -65px;
}
.progress-expand {
  border: 0px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  width: 100%;
  font-size: 13px;
  color: #555;
  background-color: transparent;
}
.progress-expand:active {
  background: linear-gradient(to bottom, #f5f5f5, #eee);
}
@media only screen and (max-width: 768px) {
  .progress-text {
    top: 50%;
    left: 42px;
    transform: translateY(-50%);
  }
  .progress-wrap {
    flex-flow: column;
    align-items: flex-start;
  }
  .progress-item {
    margin-right: 0px;
    margin-bottom: 55px;
  }
  .progress-item::after,
  .progress-item::before {
    width: 40px;
    top: 55px;
    right: 50%;
    transform: translateX(50%) rotate(90deg);
  }
  .progress-item.complete::before {
    width: 40px;
    right: 50%;
    transform: translateX(50%) rotate(90deg);
  }
  .progress-item.next::before {
    width: 20px;
    top: 45px;
    right: 50%;
    transform: translateX(50%) rotate(90deg);
  }
}
@keyframes progress {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
