.breadcrumbs {
  font-size: 14px;
  display: flex;
  font-family: "Gotham";
}
.breadcrumbs{
  padding: 16px 0px 0px 0px !important;
  margin-bottom:0px !important;
}
.breadcrumbs .breadcrumb-container {
  /* border: 1px solid #00983d29; */
  border-radius: 6px;
}

.breadcrumbs .link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}

.breadcrumbs .link a {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #A3A3A3;
  text-decoration: none;
}

.breadcrumbs .link:last-child {
  /* width: 76px; */
  height: 20px;
  left: 119.35px;
  border-radius: 3px;
  top: 0px;
  /* background: #00983d; */
  flex: none;
  order: 3;
  flex-grow: 0;
  /* margin: 0px 8px; */
  cursor: default;
  padding: 10px 0px;
  color: #2D9140;
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
}

.breadcrumbs .link .arrow {
  color: #A3A3A3;
  padding: 0px 5px;
}
@media screen and (max-width:768px) {
  .breadcrumbs{
    display: none !important;
  }
  
}