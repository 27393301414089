.grid-wrapper-8 {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  /* width: 90%; */
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;
}
.grid-wrapper-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* width: 90%; */
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;
}

.grid-title {
  font-size: 1rem;
  margin-top: 0.5rem;
  font-weight: 500;
}

.grid-image-wrapper {
  width: 6rem;
  height: 6rem;
  background: #f8ecee;
  border-radius: 50%;
  /* border: solid 1px; */
  /* padding: 0.5rem; */
  border: #ffbc55 0.15rem solid;
  cursor: pointer;
  overflow: hidden;
  /* border-color: linear-gradient(#2385bc 100%, #134866 100%); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.square-grid-image-wrapper {
  width: 5.4rem;
  height: 5.4rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-color: linear-gradient(#2385bc 100%, #134866 100%); */
}

.grid-item {
  margin: 0;
}

.grid-image {
  height: 3.8rem;
  width: 3.8rem;
  /* border-radius: 5rem; */
  /* border-width: 1rem; */
  /* border: 0.1rem solid; */
  object-fit: contain;
  margin: 0.75rem;
}
.circular-grid-image {
  height: 95%;
  width: 95%;
  /* border-radius: 5rem; */
  /* border-width: 1rem; */
  /* border: 0.1rem solid; */
  object-fit: contain;
  margin: auto;
}
/* 
.outer_circle {
  background: black;
} */

@media (max-width: 768px) {
  .grid-wrapper-8 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    row-gap: 1rem;
  }
  .grid-wrapper-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .grid-image {
    height: 3.8rem;
    width: 3.8rem;
    /* border-radius: 5rem; */
    /* border-width: 1rem; */
    object-fit: contain;
  }

  .grid-image-wrapper {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 50%;
    /* border: solid 1px; */
    /* padding: 0.5rem; */
    border: #ffbc55 0.15rem solid;
    /* border-color: linear-gradient(#2385bc 100%, #134866 100%); */
  }
  .square-grid-image-wrapper {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 8px;
    /* border: solid 1px; */
    /* padding: 0.5rem; */
    border: none;
    /* border-color: linear-gradient(#2385bc 100%, #134866 100%); */
  }
  .grid-title {
    font-size: 0.875rem;
    margin-top: 0.5rem;
    font-weight: 500;
  }
}

@media only screen and (max-width: 425px) {
  .grid-image {
    height: 4rem;
    width: 4rem;
    /* border-radius: 5rem; */
    /* border-width: 1rem; */
    object-fit: contain;
  }
  .circular-grid-image {
    /* height: 5rem;
    width: 5rem;
    margin: auto; */
    margin-top: 1rem;
  }
  .grid-image-wrapper {
    width: 5.25rem;
    height: 5.25rem;
    background: #f8ecee;
  }
  .square-grid-image-wrapper {
    width: 5rem;
    height: 5rem;
    border-radius: 7px;
    /* border: solid 1px; */
    /* padding: 0.5rem; */
    border: none;
    /* border-color: linear-gradient(#2385bc 100%, #134866 100%); */
  }
  .grid-title {
    font-size: 0.875rem;
    margin-top: 0.5rem;
    font-weight: 500;
  }
  .square-grid-title {
    font-size: 0.75rem;
    font-weight: 500;
  }
  .Grid {
    /* margin-top: 0.5rem; */
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 380px) {
  .grid-image-wrapper {
    width: 4.5rem;
    height: 4.5rem;
    background: #f8ecee;
  }
  .square-grid-image-wrapper {
    width: 4rem;
    height: 4rem;
  }
  .grid-image {
    height: 2.8rem;
    width: 2.8rem;
  }
}

@media only screen and (max-width: 320px) {
  .grid-image {
    height: 3rem;
    width: 3rem;
    /* border-radius: 5rem; */
    /* border-width: 1rem; */
    object-fit: contain;
  }
  .grid-image-wrapper {
    width: 4rem;
    height: 4rem;
  }
  .square-grid-image-wrapper {
    width: 4rem;
    height: 4rem;
  }
}
