.nesto-app {
    max-height: 100vh;
    width:100%;
    overflow-x: hidden;
}

.nesto-app .x-link {
    color:#181818;
    position: absolute;
    top:10px;
    right:10px;
    font-size: 24px;
}

.nesto-app .x-link:hover {
    text-decoration: none;
}

.nesto-app .content {
    height:100vh;
    width:100%;
}

.nesto-app .content .box-one {
    height:60%;
    background: linear-gradient(325.66deg, #81D484 20.3%, #B0E4B2 79.7%);
    position:relative;
}

.nesto-app .content .box-one .vector-1 {
    position: absolute;
    left:50%;
    bottom:0;
    transform: translate(-50%, 0);
    height:680px;
    width:1360px;
    border:2px dashed #181818;
    border-width: 2px 2px 0 2px;
    border-radius: 1020px 1020px 0 0;
    box-sizing: border-box;
    mix-blend-mode: overlay;
}

.nesto-app .content .box-one .vector-2 {
    position: absolute;
    left:50%;
    bottom:0;
    transform: translate(-50%, 0);
    height:500px;
    width:1000px;
    border:2px dashed #181818;
    border-width: 2px 2px 0 2px;
    border-radius: 750px 750px 0 0;
    box-sizing: border-box;
    mix-blend-mode: overlay;
}

.nesto-app .content .box-one .vector-3 {
    position: absolute;
    left:50%;
    bottom:0;
    transform: translate(-50%, 0);
    height:320px;
    width:640px;
    border:2px dashed #181818;
    border-width: 2px 2px 0 2px;
    border-radius: 480px 480px 0 0;
    box-sizing: border-box;
    mix-blend-mode: overlay;
}

.nesto-app .content .box-one .vector-4 {
    position: absolute;
    left:50%;
    bottom:0;
    transform: translate(-50%, 0);
    height:160px;
    width:320px;
    border:2px dashed #181818;
    border-width: 2px 2px 0 2px;
    border-radius: 240px 240px 0 0;
    box-sizing: border-box;
    mix-blend-mode: overlay;
}

.nesto-app .content .box-one .vector-5 {
    position: absolute;
    left:50%;
    bottom:0;
    transform: translate(-50%, 0);
    height:80px;
    width:160px;
    border:2px dashed #181818;
    border-width: 2px 2px 0 2px;
    border-radius: 120px 120px 0 0;
    box-sizing: border-box;
    mix-blend-mode: overlay;
}

.nesto-app .content .box-one .main {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding:32px 16px 0 16px;
    overflow:hidden;
    height:100%;
}

.nesto-app .content .box-one .main .heading {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    color:#181818;
    margin-bottom: 8px;
}

.nesto-app .content .box-one .main .details {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color:#464646;
    margin-bottom: 8px;
}

.nesto-app .content .box-one .image-box{
    display:flex;
    justify-content: center;
    align-items: flex-end;
    column-gap: 16px;
    width:100%;
}

.nesto-app .content .box-one .image-box img {
    width:100%;
}

.nesto-app .content .badge {
    padding:16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
    border-radius:500px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color:#181818;
    background-color: #EAF4EC;
}

.nesto-app .content .one {
    position: absolute;
    top:10%;
    left:14%;
}

.nesto-app .content .two {
    position: absolute;
    top:40%;
    left:4%;
}

.nesto-app .content .three {
    position: absolute;
    bottom:10%;
    left:6%;
}

.nesto-app .content .four {
    position: absolute;
    top:10%;
    right:14%;
}

.nesto-app .content .five {
    position: absolute;
    top:40%;
    right:4%;
}

.nesto-app .content .six {
    position: absolute;
    bottom:10%;
    right:6%;
}

.nesto-app .content .box-two {
    height:40%;
    display:flex;
    align-items: center;
    justify-content: space-around;
}

.nesto-app .content .box-two .details-box {
    background-color: white;
    padding:24px;
    width: 100%;
    max-width:374px;
    height:264px;
    text-align: center;
    white-space: nowrap;
}

.nesto-app .content .box-two .details-box .heading {
    font-size: 24px;
    line-height: 40px;
    font-weight: 500;
    margin-bottom: 24px;
    color:#464646;
}

@media screen and (max-width:992px) {
    .nesto-app .content {
        height:auto;
    }

    .nesto-app .content .one {
        display:none;
    }
    
    .nesto-app .content .two {
        display:none;
    }
    
    .nesto-app .content .three {
        display:none;
    }
    
    .nesto-app .content .four {
        display:none;
    }
    
    .nesto-app .content .five {
        display:none;
    }
    
    .nesto-app .content .six {
        display:none;
    }
    
}

@media screen and (max-width:576px) {
    .nesto-app .content .box-two .qr-box {
        display: none;
    }
}