.cart-table-wrap {
  background: #f5f5f8;
  border-radius: 7px;
}
.cart-page-product-img{
  width:90px;
  mix-blend-mode: multiply;
}
.cart-table-wrap td{
  display: flex;
  justify-content: center;
  flex-flow: column;
}