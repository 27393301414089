.ror-container {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 7px;
}
.rhs-section {
  border-bottom: 1px solid #e5e5e5;
}

.btn-submit {
  background: #00983d;
  border-radius: 8px;
}
