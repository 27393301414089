.OfferCard_wrapper {
  background: white;
  /* padding: 1rem; */
}

.OfferCard__title {
  color: #181818;
  font-size: 1rem;
  font-weight: 400;
}
.OfferCard__offer {
  color: #2d9140;
  font-size: 1.125rem;
  font-weight: 500;
}

.OfferCard__productImage {
  /* width: 100%; */
  height: 7rem;
  margin: auto;
}

.OfferCard {
  padding: 0.825rem;
}

@media (max-width: 768px) {
  .OfferCard__productImage {
    width: 80px;
    height: auto;
    margin: 0 auto 0 auto;
  }
  .OfferCard__title {
    font-size: 0.7rem;
  }
  .OfferCard {
    padding: 0.825rem;
    height: auto;
    width: auto;
  }

  .OfferCard__offer {
    font-size: 0.875rem;
  }
  .OfferCard {
    min-height: 30vw;
    padding: 0.5rem;
  }
}
@media (max-width: 425px) {
  .OfferCard__productImage {
    width: auto;
    height: 64px;
    margin: 0 auto 0 auto;
  }
  .OfferCard__title {
    font-size: 0.7rem;
  }

  .OfferCard__offer {
    font-size: 0.875rem;
  }

  .OfferCard {
    min-height: 0;
    padding: 0 0.5rem;
  }
}
