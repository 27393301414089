.search-wrap {
  flex-grow: 1;
  background: #f2f2f2;
  border-radius: 8px;
  margin: 0px 10px;
  display: flex;
  max-width: 600px;
  z-index: 10;
}
.search-wrap-svg {
  color: #00983d;
  font-size: 20px;
  z-index: 10;
}
.search-wrap-icon {
  padding: 10px 15px;
  user-select: none;
  z-index: 10;
}
.search-wrap-input {
  background-color: transparent;
  border: 0px;
  flex-grow: 1;
  font-size: 14px;
  padding: 5px 20px;
  outline: none;
  z-index: 10;
}
.search-wrap-input:focus {
  outline: none;
  background-color: white;
  border-radius: 8px;
}

.search-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: #212529;
  opacity: 0.2;
}

.search-hits {
  position: absolute;
  left: 0;
  top: 70px;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
}
.hits-holder-border {
  border: 2px solid #f4f6f8;
  border-top: 0 solid #f4f6f8;
}
.hits-holder {
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  display: flex;
  max-height: 600px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.double-max-width {
  max-width: 800px;
}

.suggestion-hits {
  padding: 35px;
  max-height: 500px;
  min-height: 130px;
  overflow: auto;
}
.suggestion-hits-heading {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}
.suggestion-hits-list {
  list-style-type: none;
  padding: 0;
}
.suggestion-hits-btn {
  padding: 0 !important;
  text-align: left !important;
}
.suggestion-hits-btn em {
  color: #dc3545;
}
.suggestion-hits-btn:hover {
  text-decoration: underline !important;
  color: #00983d !important;
}
.suggestion-hits-btn:focus {
  box-shadow: none !important;
}

.category-hits {
  padding: 35px;
  max-height: 500px;
  min-height: 130px;
  overflow: auto;
}
.category-hits-heading {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}
.category-hits-list {
  list-style-type: none;
  padding: 0;
}
.category-hits-link {
  color: #212529;
}
.category-hits-link em {
  color: #dc3545;
}
.category-hits-link:hover {
  color: #00983d;
}
.category-hits-link:focus {
  box-shadow: none !important;
}

.suggestion-and-category {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.product-hits {
  flex-grow: 1;
  padding: 35px;
  max-height: 500px;
  overflow: auto;
}
.product-hits-heading {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}
.product-hits-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}
.product-hits-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #212529;
  height: 100%;
}
.product-hits-link:hover {
  color: #212529;
}

.divider-container {
  padding: 15px 0;
}
.divider {
  border: 1px solid #f4f6f8;
  height: 100%;
}
.mobile-hide-search {
  display: none;
}

@media only screen and (max-width: 768px) {
  .search-wrap {
    order: 3;
  }
  .divider-container {
    padding: 0 15px;
  }
  .divider {
    width: 100%;
  }

  .suggestion-hits {
    overflow: auto;
    max-height: none;
  }

  .category-hits {
    overflow: auto;
    max-height: none;
  }

  .product-hits {
    overflow: auto;
    max-height: none;
  }
}

@media only screen and (max-width: 500px) {
  .search-hits {
    top: 104px;
  }

  .search-wrap {
    margin-bottom: 6px;
  }

  .hits-holder {
    flex-direction: column;
    overflow: auto;
    max-height: calc(100vh - 106px);
  }

  .mobile-hide-search {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 2px 10px;
  }

  .product-hits {
    padding: 16px;
    min-height: 100px;
    overflow: visible;
  }

  .suggestion-and-category {
    flex-direction: row;
    row-gap: 0;
  }

  .suggestion-hits {
    padding: 16px;
    min-height: auto;
    flex-shrink: 0;
    width: 50%;
  }

  .category-hits {
    padding: 16px;
    min-height: auto;
    flex-shrink: 0;
    width: 50%;
  }
}
