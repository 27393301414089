.DealsView2 {
  padding: 2rem;
}

.DealsView2__title {
  font-size: 1rem;
  line-height: 1.5rem;

  color: #181818;
  font-weight: 700;
  /* margin: 0px 1rem; */
}

.DealsView2__comboDeal {
  height: 100%;
  border-right: 1px solid black;
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.deals-view-slider {
  max-width: 70%;
  padding-left: 1rem;
}

.DealsView2__subtitle {
  font-size: 0.75rem;
  font-weight: 325;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}

.DealsView2__comboDeal {
  max-width: 30%;
  padding-right: 1rem;
}

.DealsView2__comboImage {
  max-width: 100%;
}

.DealsView2__slider-product {
  width: 7rem;
  display: flex;
  margin-right: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: unset;
  padding: 1rem;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
}

.dealsView2__productName {
  font-weight: 325;
  font-size: 0.75rem;
}

.dealsView2__productPrice {
  font-weight: bold;
  font-size: 0.875rem;
}

.dealsView2__sliderImage {
  width: 5.7rem;
  height: 5.75rem;
}
.DealsView2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.deals-view-v2-wrapper {
  display: none;
}

@media only screen and (max-width: 768px) {
  .DealsView2 {
    padding: 1rem;
  }
  .DealsView2 {
    flex-direction: column;
  }
  .DealsView2__title {
    margin-left: 0 !important;
    margin-top: 1rem;
  }
  .deals-view-slider {
    max-width: 100%;
    padding-left: 0;
  }

  .DealsView2__subtitle {
    font-size: 0.75rem;
    font-weight: 325;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
  }

  .DealsView2__comboDeal {
    max-width: 100%;
    padding-right: 1rem;
  }

  .DealsView2__comboDeal {
    height: 100%;
    border-right: 0;
    border-bottom: 1px solid black;
    display: flex;
    padding-bottom: 1rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 425px) {
  .DealsView2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .deals-view-v2-wrapper {
    display: block;
    margin-bottom: 0;
  }
}
