.cart-card-container-grid {
  border: 2px solid #f5f5f8;
  min-height: 300px !important;
  width: 100% !important;
}

.cart-card-container-horizontal {
  border: 2px solid #f5f5f8;
  min-height: 300px !important;
  width: 160px !important;
  /* border-radius: 10px; */
}

.cart-card-product-name {
  font-style: normal;
  font-weight: bold;
  font-size: 12.5px;
  height: 37px;
}
.cart-card-image {
  padding-top: 10px !important;
  max-height: 120px;
  max-width: 120px;
  width: auto;
  height: auto;
  cursor: pointer;
}

.cart-qd-box {
  border: 2px solid;
  border-radius: 3.5px;
  margin: 2px;
  font-size: 11px;
  font-weight: 700;
  width: 50px !important;
  text-align: center;
}

.cart-quantity {
  border-color: #00983d;
}

.cart-discount {
  background: #ffb930;
  border-color: #ffb930;
  color: white;
}

.cart-price-special {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
}
.cart-card-lower {
  position: absolute;
  right: 10px;
  left: 10px;
  bottom: 10px;
}
.fav-icons {
  transform: translateX(0px) translateY(0px);
}
.heart-beat {
  animation: heart-beats 0.3s linear infinite;
}
.hurry-items{
  position: absolute;
  top:10px;
  left:10px;
  font-size: 10px;
  font-weight: 600;
  color: tomato;
}
/* .heart-broken {
  animation: heart-brokens 0.15s linear infinite;
} */
@keyframes heart-beats {
  0% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(2px);
  }
}
/* @keyframes heart-brokens {
  0% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(2px);
  }
} */
