.action-card-container {
  border-radius: 10px;
  padding: 10px 25px !important;
  cursor: pointer;
}
.action-card-warning {
  background-color: #ffeab6;
}

.action-card-failed {
  background-color: #ffded1;
}


.action-card-success {
  background: #f5f5f8;
}

.action-card-text1 {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: #757d85;
}

.action-card-text2 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #111a2c;
}

.action-card-icon {
  mix-blend-mode: multiply;
  max-width: 40px !important;
}
