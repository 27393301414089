.add-cart-button {
  border-radius: 4px;
  font-size: 0.8em;
  /* padding: 5px 0; */
  cursor: pointer;
  user-select: none;
  font-style: normal;
  font-weight: bold;
  color: #ffffff;
  min-width: 82px !important;
}
