.checkout-page{
  margin-bottom: 32px;
  font-family: "Gotham";
}

.checkout-page .text-sm {
  font-size: 14px;
}

.checkout-page .content {
  column-gap: 16px;
}

.checkout-page .content .menu {
  flex-grow: 1;
}

.checkout-page .content .menu .step-box{
  display:flex;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding:16px;
}

.checkout-page .content .menu .step-box .step-name {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-right: 6px;
}

.selected-address {
  background-color: #EAF4EC;
}

.checkout-page .content .menu .step-box .step-details {

}

.checkout-page .content .menu .step-box .step-details-mini {
  display:flex;
  align-items: flex-start;
  justify-content: space-between;
}

.checkout-page .content .menu .step-box .step-details-full {
  
}

.checkout-page .content .menu .step-box .address-list {
  max-height:400px;
  overflow-y: auto;
}

.checkout-page .content .menu .step-box .address-box {
  padding:16px 32px;
}

.checkout-page .content .menu .heading {
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
}

.checkout-page .content .cart-details {
  padding: 8px 16px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.08);
  width:100%;
  max-width: 480px;
}

.checkout-page .content .cart-details .heading {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

.checkout-page .timeslot-btn {
  border: 1px solid #A3A3A3;
  border-radius: 4px;
  color:#A3A3A3;
  padding:8px;
  width:140px;
}

.checkout-page .timeslot-badge {
  border:1px solid #96C8A0;
  padding:8px;
  color:#2D9140;
  background-color: #EAF4EC;
  width:140px;
}

.mastercard {
  box-sizing: content-box;
  width: 25px;
  height: 16px;
  background: transparent url("../../assets/images/master-card.svg") no-repeat center;
}

.payment-label {
  position: relative;
  cursor: pointer;
}

.green-accent {
  accent-color: #2D9140;
}

.selected-payment {
  background-color: white;  
}

.btn-line-success {
  color: #28a745 !important;
  border:none;
}

.btn-line-success:hover {
  background-color: #EAF4EC;
}

.sidebar-cart-listing {
  max-height: 400px;
  overflow-y: auto;
}

.subtotal-text {
  color:#464646;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.amount-text {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #464646;
}

.select-button {
  border: 2px solid #E8E8E8;
  border-radius: 4px;
  color:#111111;
  padding: 12px 16px;
  background-color: white;
  width:100%;
  display:flex;
  justify-content: space-between;
}

.address-modal-box {
  padding:16px 10px;
}

.custom-arrow {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.mobile-fixed-checkout {
  position:fixed;
  bottom: 0;
  left:0;
  width:100%;
  background-color: white;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

.mobile-fixed-checkout .item-details {
  font-size: 12px;
  line-height: 18px;
}

.mobile-fixed-checkout .cart-amount {
  font-size: 16px;
  line-height: 24px;
}

.selected-payment-method{
  background-color: #EAF4EC;
}

.cvv-input {
  -webkit-text-security: disc;
}

@media screen and (max-width:768px) {
  .checkout-page .content .cart-details {
    max-width: 100%;
  }

  .checkout-page .content .menu .heading {
    font-size: 16px;
    line-height: 24px;
  }

  .checkout-page .content .menu .step-box {
    box-shadow: none;
    padding: 0;
  }

  .selected-payment{
    background-color: #EAF4EC;
  }

  .checkout-page {
    margin-bottom:110px;
  }
}