.dynamic-list-image-wrapper {
  padding: 1rem;
  width: 30%;
}

.dynamic-list-title {
  font-size: 0.75rem;
  margin-bottom: 0.75rem;
  font-weight: 325;
  margin-top: 0.5rem;
}
.dynamic-list-rating {
  margin-bottom: 0.5rem;
}

.dynamic-list-reviews {
  font-size: 0.75rem;
  font-weight: 325;
  color: #747474;
  margin-left: 0.2rem;
}

.dynamic-list-content-wrapper {
  margin-left: 0.5rem;
}

.dynamic-list-discounted-price {
  font-size: 0.875rem;
  font-weight: 500;
  color: #181818;
}

.dynamic-list-original-price {
  font-weight: 325;
  font-size: 0.75rem;
  color: #a3a3a3;
  margin-left: 0.25rem;
}

.dynamic-list-discount {
  font-weight: 325;
  margin-left: 0.25rem;
  font-size: 0.625rem;
}

.dynamic-list-price-wrapper {
  margin-bottom: 0.5rem;
}

.dynamic-list-delivery {
  font-weight: 325;
  font-size: 12px;
  color: #747474;
}

.dynamic-list-delivery-days {
  font-weight: 500;
  color: black;
}

.widget-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  gap: 1.5rem;
}

@media only screen and (max-width: 1005px) {
  .widget-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0rem;
  }
}
