.login-brading-container {
  overflow: hidden;
}
.login-image {
  /* transform: rotate(-55deg); */
  position: absolute;
  width: 180%;
  bottom: 0;
  right: -50%;
  mix-blend-mode: darken;
}
.branding-main {
  position: relative;
  color: crimson;
  font-weight: 900;
  z-index: 999999;
}

.phone-input {
  width: 100%;
}
.phone-country-dropdown {
  padding: 0 !important;
  border: none;
  background-color: transparent !important;
  outline: 0 !important;
  z-index: 99999;
}
.phone-input-number {
  outline: 0;
  border: none;
  border-bottom: 1px solid mediumseagreen;
}
.phone-input-number:focus {
  border-bottom: 2px solid seagreen;
}
.otp-input {
  min-width: 45px;
  min-height: 50px;
}
.green-link {
  text-decoration: none !important;
  color: mediumseagreen;
  font-weight: 700;
  cursor: pointer;
}

.green-link:hover {
  color: seagreen;
  font-weight: 900;
}

.phone-input-label {
  font-weight: 700;
  font-size: 14px;
  color: grey;
}

.country-popover {
  border: 0px !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px !important;
  overflow: hidden;
  margin-top: 0px !important;
  width: 70px !important;
}
.country-popover .arrow,
.country-popover .arrow::before,
.country-popover .arrow::after {
  border: 0px !important;
  height: 0px !important;
}
