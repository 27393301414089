.title {
  font-weight: normal;
  font-size: 0.75rem;
  color: #181818;
  margin-top: 2rem;
}
.current-price {
  font-weight: bold;
  font-size: 1rem;

  color: #2d9140;
}

.price-wrapper {
  margin-top: 0.5rem;
}

.original-price {
  font-weight: normal;
  font-size: 0.75rem;

  color: #747474;
}
