.rating-popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.rating-popup .modal-dialog-centered {
    max-width: 650px !important;
}
.rating-popup .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3em 5em;
}
.rating-popup .input-element {
    padding: 1em;
}
.rating-popup .star {
    color: gold;
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin: 5px;
}
.rating-popup .fill-star {
    color: gold;
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin: 5px;
    fill: gold;
}
.rating-popup .heading {
    font-size: 24px;
    font-weight: 500;
}
.rating-popup .info {
    font-size: 13px;
    font-weight: 400px;
}
.rating-popup .continue-btn {
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    background: #00983D;
    border-radius: 10.8039px;
    border: none;
    padding: 8px 30px;
    font-family: inherit;

}