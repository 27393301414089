@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-XLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Gotham Ultra";
  src: url("./fonts/Gotham/Gotham-UltraItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-BookItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Ultra";
  src: url("./fonts/Gotham/Gotham-Ultra.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-XLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*Custom ScrollBar */

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(184, 184, 184);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b9b9b9;
}

/*Custom ScrollBar */
