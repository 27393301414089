.notification-heading {
  color: #333;
  font-size: 15px;
  font-weight: bold;
}
.notification-clear {
  border: 0px;
  background-color: transparent;
  color: #00983d;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.flips {
  animation: flips 01s linear infinite;
}
@keyframes flips {
  0% {
    transform: rotateY(0deg);
  }50%{
    transform: rotateY(-180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
